import { Box, Divider, IconButton, List, ListItem, Popover, Tooltip, Typography, styled, Badge } from "@mui/material";
import { useRef, useState, useCallback, useEffect } from "react";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { formatDistance, subHours } from "date-fns";
import { GET_ALL_USER_NOTIFICATION, UPDATE_USER_NOTIFICATION } from "../../../utils/services/apiPath";
import { postApi } from "../../../utils/services";
import NotificationIcon from "../../Assets/IconComponents/Notification";
import { loginSelector } from "../../store/features/auth/authSlice";
import { errorToast } from "../../../utils/toastHelper";
import { useSelector } from "react-redux";

const AnimatedBadge = styled(Badge)(
  ({ theme }) => `
    
    .MuiBadge-badge {
        box-shadow: 0 0 0 2px ${theme.palette.background.paper};
        background-color: #44b700;
        color: #44b700;
        
        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            animation: ripple 1.2s infinite ease-in-out;
            border: 1px solid currentColor;
            content: "";
        }
    }
`
);

function HeaderNotifications() {
  const ref = useRef(null);
  const { user } = useSelector(loginSelector);
  const clientUserId = user?.id;

  const [isOpen, setIsOpen] = useState(false);
  const [markAsRead, setMarkAsRead] = useState(false);
  const [userNotifications, setUserNotifications] = useState([]);

  const handleOpen = () => {
    setMarkAsRead(false);
    fetchClientUserNotifications();
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (!clientUserId) fetchClientUserNotifications();
  }, []);

  const fetchClientUserNotifications = useCallback(async () => {
    const payload = {
      pageIndex: 1,
      pageSize: 100,
      orderByAscending: false,
      searchString: ""
    };
    const { error, data } = await postApi(GET_ALL_USER_NOTIFICATION, payload, { params: { clientUserId: clientUserId } });
    if (error) return errorToast(error);
    setUserNotifications(data || []);
    setIsOpen(true);
  }, []);

  const handleReadAll = useCallback(async () => {
    const { error } = await postApi(UPDATE_USER_NOTIFICATION, {}, { params: { clientUserId: clientUserId } });
    if (error) return errorToast(error);
    setMarkAsRead(true);
  }, []);

  const { t } = useTranslation();

  return (
    <>
      <Tooltip arrow title={t("Notifications")}>
        <IconButton onClick={handleOpen}>
          <NotificationIcon isUnReadNotifications={markAsRead} />
        </IconButton>
      </Tooltip>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <Box
          sx={{
            p: 2,
            width: 450
          }}
          display="flex"
          justifyContent="space-between"
        >
          <Typography variant="h5">{t("Notifications")}</Typography>
          {userNotifications?.length === 0 ? (
            <></>
          ) : (
            <Link
              href="#"
              variant="caption"
              sx={{
                textTransform: "none"
              }}
              onClick={handleReadAll}
            >
              {t("Mark all as read")}
            </Link>
          )}
        </Box>
        <Divider />
        <List
          sx={{
            p: 2
          }}
        >
          {userNotifications?.length === 0 ? (
            <Typography component="span" variant="body2">
              No notification here
            </Typography>
          ) : (
            userNotifications?.map(item => {
              return (
                <>
                  <ListItem
                    sx={{
                      display: { xs: "block", sm: "flex" }
                    }}
                  >
                    {item.isSeen === false && markAsRead === false ? (
                      <AnimatedBadge overlap="circular" badgeContent=" " variant="dot" sx={{ mr: 3 }} />
                    ) : (
                      <AnimatedBadge sx={{ mr: 3 }} />
                    )}
                    <Box flex={1} sx={{ maxWidth: 450 }}>
                      <Box display={{ xs: "block", sm: "flex" }} justifyContent="space-between">
                        <Box
                          component={RouterLink}
                          to={item.moduleLink}
                          sx={{
                            fontWeight: "bold"
                          }}
                        >
                          <Typography component="span" variant="body2">
                            {item.module}
                          </Typography>
                        </Box>
                        <Typography
                          variant="caption"
                          sx={{
                            textTransform: "none"
                          }}
                        >
                          {formatDistance(subHours(new Date(item.createdDate), 3), new Date(), {
                            addSuffix: true
                          })}
                        </Typography>
                      </Box>
                      <Typography component="span" variant="body2">
                        {item.notificationMessage}
                      </Typography>
                    </Box>
                  </ListItem>
                  <Divider
                    variant="inset"
                    sx={{
                      my: 1
                    }}
                    component="li"
                  />
                </>
              );
            })
          )}
        </List>
        <Divider />
      </Popover>
    </>
  );
}

export default HeaderNotifications;
