import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProPreLoginLayout from "../../Components/ProPreLoginLayout";
import ProTextInput from "../../Components/Inputs/TextField";
import ProButton from "../../Components/ProButton";
import { useForm } from "react-hook-form";
import { postApi, getApi } from "../../../utils/services";
import { ACCEPT_REJECT_MENTOR_REQUEST, APPRENTICE_REQUEST_DETAILS } from "../../../utils/services/apiPath";
import { errorToast } from "../../../utils/toastHelper";
import { fieldTruePermission, prepareDefaultValues, prepareInitialConfig, renderFormattedDate } from "../../../utils/formHelper";
import ProRadioGroup from "../../Components/Inputs/RadioGroup";
import dayjs from "dayjs";

export const yesNoOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false }
];

const ConfirmMentorConfig = {
  isMentorWorking: {
    label: "Are you Working Today?",
    defaultValue: null,
    rules: { required: "This field is required!" }
  },
  notWorkingReason: {
    name: "notWorkingReason",
    label: "Reason",
    fieldProps: { multiline: true, rows: 4 }
  }
};

const todayDate = renderFormattedDate(dayjs());

const ConfirmMentor = () => {
  const { id } = useParams();
  const [projectDetail, setProjectDetail] = useState();
  const [isRequested, setIsRequested] = useState(false);
  const [message, setMessage] = useState(false);
  const [formConfig] = useState(prepareInitialConfig(ConfirmMentorConfig, "edit"));
  const { control, watch } = useForm({ defaultValues: prepareDefaultValues(ConfirmMentorConfig) });
  const isMentorWorking = watch("isMentorWorking");
  const isnotWorkingReason = watch("notWorkingReason");
  const [isReject, setIsReject] = useState(false);

  const onSubmit = async () => {
    const payload = {
      projectApprenticeMentorGuid: projectDetail?.projectApprenticeMentorGuid,
      isAccepted: true,
      isApprenticeWorking: true
    };
    const { error } = await postApi(ACCEPT_REJECT_MENTOR_REQUEST, payload);
    if (error) return errorToast(error);
    fetchApprenticeRequestDetails();
  };

  const handleRejectReason = async () => {
    setIsReject(true);
  };

  const handleReasonRequest = async () => {
    const payload = {
      projectApprenticeMentorGuid: projectDetail?.projectApprenticeMentorGuid,
      isAccepted: false,
      isApprenticeWorking: isMentorWorking == "true",
      notWorkingReason: isnotWorkingReason
    };
    const { error } = await postApi(ACCEPT_REJECT_MENTOR_REQUEST, payload);
    if (error) return errorToast(error);
    fetchApprenticeRequestDetails();
  };

  const handleReasonForRejection = async () => {
    const payload = {
      projectApprenticeMentorGuid: projectDetail?.projectApprenticeMentorGuid,
      isAccepted: false,
      isApprenticeWorking: isMentorWorking == "true",
      rejectReason: isnotWorkingReason
    };
    const { error } = await postApi(ACCEPT_REJECT_MENTOR_REQUEST, payload);
    if (error) return errorToast(error);
    fetchApprenticeRequestDetails();
  };

  const fetchApprenticeRequestDetails = async () => {
    const { data, error, message } = await getApi(`${APPRENTICE_REQUEST_DETAILS}`);
    if (error) {
      setIsRequested(true);
      setMessage(message || error.message || error);
    } else {
      setIsRequested(false);
      setProjectDetail(data);
    }
  };

  useEffect(() => {
    fetchApprenticeRequestDetails();
  }, [id]);

  const defaultFormProps = { control, formValues: ConfirmMentorConfig, isAddMode: false };

  return (
    <ProPreLoginLayout heading="Mentor Pairing Request">
      <div className="proLogin">
        {!isRequested ? (
          <div className="proLogin_form">
            <div className="proLogin_form_input">
              <ProTextInput label={"Today's Date"} control={control} mode={"read"} name={"todayDate"} formValues={{ todayDate }} />
            </div>
            <div className="proLogin_form_input">
              <ProTextInput label={"Project Name"} control={control} mode={"read"} name={"projectName"} formValues={projectDetail} />
            </div>
            <div className="proLogin_form_input">
              <ProTextInput label={"Mentor Name"} control={control} mode={"read"} name={"mentorName"} formValues={projectDetail} />
            </div>
            <div className="proLogin_form_input">
              <ProRadioGroup {...defaultFormProps} {...formConfig.isMentorWorking} options={yesNoOptions} permission={fieldTruePermission} />
            </div>
            {isMentorWorking == "true" && (
              <>
                <div className="proLogin_form_button" style={{ marginBottom: "5px" }}>
                  <ProButton style={{ width: "100%" }} onClick={onSubmit}>
                    Accept Request
                  </ProButton>
                </div>
                <div className="proLogin_form_button">
                  <ProButton style={{ width: "100%" }} onClick={handleRejectReason}>
                    Reject Request
                  </ProButton>
                </div>
                {isReject && (
                  <>
                    <div className="proLogin_form_input">
                      <ProTextInput {...defaultFormProps} {...formConfig.notWorkingReason} permission={fieldTruePermission} />
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <ProButton style={{ width: "100%" }} onClick={handleReasonForRejection}>
                        Submit
                      </ProButton>
                    </div>
                  </>
                )}
              </>
            )}
            {isMentorWorking == "false" && (
              <>
                <div className="proLogin_form_input">
                  <ProTextInput {...defaultFormProps} {...formConfig.notWorkingReason} permission={fieldTruePermission} />
                </div>
                <div style={{ marginTop: "10px" }}>
                  <ProButton style={{ width: "100%" }} onClick={handleReasonRequest}>
                    Submit
                  </ProButton>
                </div>
              </>
            )}
          </div>
        ) : (
          <p style={{ color: "green", textAlign: "center", marginTop: "5px" }}>{message}</p>
        )}
      </div>
    </ProPreLoginLayout>
  );
};

export default ConfirmMentor;
