import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import ProGrid from "../../Components/ProGrid/v2";
import usePageState from "../../../utils/customHooks/usePageState";
import useColumn from "../../../utils/customHooks/useColumns";
import { STAUTES } from "../../../utils/constant";
import { useTranslation } from "react-i18next";
import { getApi, postApi } from "../../../utils/services";
import { GET_ALL_PROJECT_BY_EMPLOYEE, GET_APPRENTICE_FORM_STATUS } from "../../../utils/services/apiPath";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { loginSelector } from "../../store/features/auth/authSlice";
import RegistrationPendingModel from "../../Components/RegistrationPendingModel";
import { currencyColumnDef } from "../../Components/ProGrid/components/FormatCell";
import { errorToast } from "../../../utils/toastHelper";

export default function ProjectList() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const { user } = useSelector(loginSelector);

  const {
    currentPage,
    pageSize,
    data: projectsData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();

  const columns = useColumn(
    [
      {
        field: "companyName",
        headerName: "Company"
      },
      {
        field: "projectName",
        headerName: "Project"
      },
      {
        field: "journeyman",
        headerName: "Journeyman"
      },
      {
        field: "otjHours",
        headerName: "OTJHours"
      },
      {
        field: "onlineTraining",
        headerName: "OnlineTraining"
      },
      currencyColumnDef({ field: "payout", headerName: "Payout" })
    ],
    true
  );
  const handleProfileUpdate = async () => {
    if (user.projectEmployeeIsMentor) return;
    const { data } = await getApi(`${GET_APPRENTICE_FORM_STATUS}/${user?.employeeId}`);
    if (!data?.isFormCompleted) {
      setOpen(true);
    }
  };

  const getProjectList = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const payload = {
      pageIndex: searchString ? 1 : currentPage + 1,
      pageSize: pageSize,
      searchString: searchString,
      orderByAscending: true,
      ...sortModel
    };
    const { data, totalRecords, error } = await postApi(GET_ALL_PROJECT_BY_EMPLOYEE, payload, {
      params: { employeeId: user?.employeeId }
    });

    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);

    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  useEffect(() => {
    handleProfileUpdate();
    getProjectList();
  }, [getProjectList]);

  return (
    <>
      <Layout>
        <ProGrid
          title={t("Projects")}
          columns={columns}
          loading={status === STAUTES.LOADING}
          rows={projectsData}
          searchMode={!!searchString}
          searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
          hideAddButton={true}
          hideExport={false}
          options={{
            getRowId: row => row.projectId,
            rowCount: rowCount,
            paginationMode: "server",
            paginationModel: { pageSize: pageSize, page: currentPage },
            onPaginationModelChange: setPagination,
            sortingMode: "server",
            onSortModelChange: setSortModel
          }}
        />
      </Layout>

      <RegistrationPendingModel
        open={open}
        title={t("ApprenticeRegistrationPendingTitle")}
        message={t("ApprenticeRegistrationPendingText")}
        onConfirm={() => navigate("/registration")}
        confirmBtnText={t("Ok")}
      />
    </>
  );
}
