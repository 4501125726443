import React from "react";
import Layout from "../../Components/Layout";
import "./index.scss";
import FileUploadField from "../../Components/Inputs/FileUploadField";
import { useForm } from "react-hook-form";
import ProButton from "../../Components/ProButton";
import { convertFileToBase64 } from "../../../utils/formHelper";
import { postApi } from "../../../utils/services";
import { errorToast, successToast } from "../../../utils/toastHelper";
import { useNavigate } from "react-router";
import { UPLOAD_EMPLOYEE_PROFILE } from "../../../utils/services/apiPath";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { loginSelector } from "../../store/features/auth/authSlice";

export default function MyAccount() {
  const { user } = useSelector(loginSelector);
  const { t } = useTranslation();
  const { handleSubmit, control, watch } = useForm({ defaultValues: { profile: null } });
  const navigate = useNavigate();
  const profile = watch("profile");

  const onUpload = async () => {
    const byteArray = await convertFileToBase64(profile);
    const payload = {
      fileName: profile?.name,
      byteArray: byteArray,
      employeeId: user?.employeeId
    };

    const { error, data } = await postApi(UPLOAD_EMPLOYEE_PROFILE, payload);
    if (error) return errorToast(error);
    successToast("Logo successfully updated");
    navigate("/dashboard");
    if (data.path) {
      localStorage.setItem("user", JSON.stringify({ ...user, clientUserProfilePicture: data.path }));
    }
  };
  return (
    <Layout>
      <div className="proForm-layout proSettingForm">
        <h1>{t("MyAccount")}</h1>
        <div className="upload-profile-picture">
          <FileUploadField control={control} name="profile" label={t("ProfilePicture")} mode="edit" />
        </div>
        <ProButton disabled={!profile} onClick={handleSubmit(onUpload)}>
          {t("Upload")}
        </ProButton>
      </div>
    </Layout>
  );
}
