const htJSON = {
  Portal: 'Portal',
  General: 'Jeneral',
  Dashboard: 'Tablo',
  LanguageSwitcher: 'Chanjè lang',
  Notifications: 'Notifikasyon',
  MyAccount: 'Kont mwen',
  ThemeSettings: 'Anviwònman Tem',
  GeneralSettings: 'Anviwònman Jeneral',
  DarkColorSchemes: 'Skèm koulè wouj',
  LightColorSchemes: 'Skèm koulè klè',
  Signout: 'Dekonekte',
  Projects: 'Pwojè',
  Registration: 'Enskripsyon',
  SubHeading: 'Rempli fòm nan anba a',
  ApprenticePersona: 'Pèsonaj Aprenti',
  CurrentProject: 'Pwojè Kouran',
  Location: 'Kote',
  RAPCategory: 'Kategori RAP',
  RegisteredApprenticeshipProgram: 'Pwogram Apprentisaj ki Enskri',
  Mentor: 'Mantò',
  Employer: 'Anplwayè',
  WageEarned: 'Salè Genyen jiska Jodi a',
  PendingApproval: 'Apwobasyon nan Atant',
  WPSAdjPending: 'WPS Ajisteman. nan Atant',
  WPSSubmission: 'Sijè WPS',
  OTJHours: 'Èdtan OTJ / Èdtan Travay Proses ',
  RTIHours: 'Èdtan RTI Acheve',
  OTJTooltipText: 'Èdtan nan Travay / Pwosesis nan Wòk',
  RTITooltipText: 'Instruksyon Teknik ki Relasyone',
  WPSTracker: 'Siklon nan WPS',
  RTITracker: 'Siklon nan RTI',
  InProgress: 'An Pwogrè',
  Completed: 'Fen',
  Company: 'Konpayi',
  Project: 'Pwojè',
  Journeyman: 'Journeyman',
  OnlineTraining: 'Fòmasyon sou Entènèt',
  BasicInformation: 'Enfòmasyon Bazik',
  Payout: 'Peyi',
  FirstName: 'Non',
  MiddleName: 'Non nan mitan',
  LastName: 'Non fanmi',
  CellPhone: 'Telefòn Selilè',
  Email: 'Imèl',
  SSN: 'SSN',
  DateofBirth: 'Dat ou Fèt',
  Sex: 'Sèks',
  ApprenticeshipNumber: 'Nimewo Apprentisaj',
  ApprenticeInformation: 'Enfòmasyon Aprenti',
  Ethnicity: 'Etnisite',
  VeteranStatus: 'Estati Veteran',
  Race: 'Ras',
  EducationalLevel: 'Nivo Edikasyon',
  Disability: 'Andikap',
  EmploymentStatus: 'Estati Anplwayman',
  DateApprenticeshipBegins: 'Dat Kòmansman Apprentisaj',
  CareerConnection: 'Koneksyon Karyè',
  ApprenticeshipsProgram: 'Pwogram Apprentisaj',
  LearningExperience: 'Any Kredi pou Eksperyans Aprentisaj Sou travay (Total tan an se 4000 Édtan)',
  InstructionExperience: 'RIL oswa Instruksyon ki Relasyone, Any Kredi pou Eksperyans Anvan?',
  AddressInformation: 'Enfòmasyon Adrès',
  Address: 'Adrès',
  Address2: 'Adrès 2',
  State: 'Eta',
  City: 'Vil',
  Zip: 'Kòd Postal',
  Submit: 'Soumèt',
  Cancel: 'Anile',
  WPSTimeAllocation: 'Alòkasyon Tan WPS',
  MyCourses: 'Kou mwen',
  ApprovedHours: 'Èdtan Apwouve',
  RequiredHours: 'Èdtan Obligatwa',
  TotalApprovedHours: 'Total Èdtan Apwouve : ',
  ApprovedBy: 'Apwouve Pa -',
  WPSPendingApproval: 'WPS Apwobasyon nan Atant',
  Documents: 'Dokiman yo',
  FormName: 'Non fòm',
  CreatedDate: 'Dat Kreye',
  ESignedStatus: 'Estati E-Siyen',
  RAPIDStatus: 'Estati RAPID',
  Actions: 'Aksyon',
  SignDocument: 'Siyen Dokiman',
  ProfilePicture:'Foto pwofil',
  Upload:'Telechaje',
  CreditHours:'Orè kredi',
  CreditHoursProgress:'Pwogrè Orè kredi',
  IsApproved:'Èske Apwouve',
  WeekEnding: 'Semenn ki Fini',
  WPSPendingApprovalTooltip:'Orè Pwosesis Travay annatant Apwobasyon',
  WPSPendingAdjustmentTooltip:'Ajisteman Orè Pwosesis Travay annatant',
  AccessUserManualTootip:'Klike la a pou jwenn aksè nan manyèl itilizatè a',
  ApprenticeRegistrationPendingTitle:'Enskripsyon apranti annatant',
  ApprenticeRegistrationTitle:'Enskripsyon apranti',
  ApprenticeRegistrationPendingText:'Li sanble ke ou rate ranpli kèk nan done ki nesesè pou nou trete Enskripsyon Apranti ou a nan Depatman Travay, tanpri klike sou OK pou navige nan Fòm Enskripsyon an.',
  ApprenticeRegistrationText:'Fòm Enskripsyon Apranti ou an (Fòm 671) kounye a disponib pou siyati, tanpri klike sou OK pou wè epi siyen Fòm Enskripsyon an.',
  EditDetails: 'Edite Detay yo',
  Save: 'Sove',
  MyMentors: 'Mantò yo',
  MentorName: 'Non Mentor la',
  ApprenticeName: 'Non Aprenti a',
  ProjectName: 'Non Pwojè a',
  WorkDate: 'Dat Travay la',
  employeeName: 'Non anplwaye a',
  estimatedHours: 'Èdtan Estime',
  Videos: "Videyo"
};

export default htJSON;