import React, { forwardRef, useState } from "react";
import ProPreLoginLayout from "../../Components/ProPreLoginLayout";
import ProTextInput from "../../Components/Inputs/TextField";
import ProButton from "../../Components/ProButton";
import {  useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Box, Alert, Slide, Dialog, Collapse, Button, Avatar, FormHelperText, InputAdornment, IconButton } from "@mui/material";
import CheckTwoToneIcon from "@mui/icons-material/CheckTwoTone";
import { postApi } from "../../../utils/services";
import {  AUTH_RESET_PASSWORD } from "../../../utils/services/apiPath";
import { fieldTruePermission } from "../../../utils/formHelper";
import { useTheme } from "../../ContextStore/ThemeContext";
import { encryptText } from "../../../utils/crypto";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export const ResetPasswordConfig = {
  password: {
    name: "password",
    label: "Password",
    rules:{ 
      required: "Password is required!",
      minLength: { 
        value: 8, 
        message: "Password must be at least 8 characters." 
      } 
    }
  },
  confirmPassword: {
    name: "confirmPassword",
    label: "Confirm Password",
    rules: { required: "Confirm Password is required!",
    minLength: { 
      value: 8, 
      message: "Confirm Password must be at least 8 characters." 
    } 
     }
  }
};

const ResetPassword = () => {
  const [openAlert] = useState(true);
  const [message, setMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [helperText, setHelperText] = useState("");
  const { theme } = useTheme();
  const cellStyles = theme === "dark" ? { color: "#ffffff" } : { color: "#000000" };
  const cellStyle = theme === "dark" ? { background: "#202328", px: 4, pb: 4, pt: 10 } : { background: "#ffffff", px: 4, pb: 4, pt: 10 };
  const location = useLocation();
  const clientGuid = location.state?.clientGuid;
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    navigate("/");
  };
  const { handleSubmit, control } = useForm({});
  
  const handleClick = async formData => {
    const { password, confirmPassword } = formData;
    if (password !== confirmPassword) {
      setHelperText("Passwords do not match");
      return;
    }
    if (clientGuid) {
      const payload = {
        password: encryptText(formData?.password),
        userGuid: clientGuid
      };
      const { error, message } = await postApi(AUTH_RESET_PASSWORD, payload);
      if (error) return setHelperText(error);
      setMessage(message);
      handleOpenDialog();
      setHelperText("");
    }
  };

  return (
    <>
      <ProPreLoginLayout heading="Reset Password">
        <div className="proLogin">
          <p className="proLogin_form_info" style={cellStyles}>
            Fill in the fields below to reset your account password.
          </p>
          <div className="proLogin_form">
            <div className="proLogin_form_input">
            <ProTextInput
                {...ResetPasswordConfig.password}
                control={control}
                permission={fieldTruePermission}
                fieldProps={{
                  type: showPassword ? "text" : "password",
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                }}
              />
            </div>
            <div className="proLogin_form_input">
              <ProTextInput
                {...ResetPasswordConfig.confirmPassword}
                control={control}
                permission={fieldTruePermission}
                fieldProps={{
                  type: showConfirmPassword ? "text" : "password",
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                }}
              />
            </div>
            {helperText && (
              <FormHelperText error className="proLogin_form_error needBottomSpace">
                {helperText}
              </FormHelperText>
            )}
            <div className="proLogin_form_button" style={{ marginBottom: "10px" }}>
              <ProButton style={{ width: "100%" }} onClick={handleSubmit(handleClick)}>
                Reset Password
              </ProButton>
            </div>
          </div>
        </div>
      </ProPreLoginLayout>
      <Dialog
        className="proLogin_dialog"
        open={openDialog}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
      >
        <Box sx={cellStyle}>
          <Avatar className="proLogin_dialog_avatar">
            <CheckTwoToneIcon />
          </Avatar>

          {message && (
            <Collapse in={openAlert}>
              <Alert>{message}</Alert>
            </Collapse>
          )}

          <Button fullWidth size="large" sx={{ mt: 3 }} variant="contained" onClick={handleCloseDialog}>
            Continue to login
          </Button>
        </Box>
      </Dialog>
    </>
  );
};

export default ResetPassword;
