import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import ProGrid from "../../Components/ProGrid/v2";
import useColumn from "../../../utils/customHooks/useColumns";
import usePageState from "../../../utils/customHooks/usePageState";
import { STAUTES } from "../../../utils/constant";
import { postApi } from "../../../utils/services";
import { GET_ALL_EMPLOYEE_TIME_ALLOCATION } from "../../../utils/services/apiPath";
import { useTranslation } from "react-i18next";
import { errorToast } from "../../../utils/toastHelper";
import { useSelector } from "react-redux";
import { loginSelector } from "../../store/features/auth/authSlice";
import NameCell from "../../Components/ProGrid/components/NameCell";
import DailyTimeAllocationFormModal from "../../Components/ModuleComponent/wps/DailyTimeAllocationFormModal";
import { dateColumnDef } from "../../Components/ProGrid/components/FormatCell";

export default function DailyTimeAllocation() {
  const { user } = useSelector(loginSelector) || {};
  const { employeeId } = user;
  const { t } = useTranslation();
  const [showTimeAllocationModal, setShowTimeAllocationModal] = useState(false);
  const [actionData, setActionData] = useState(null);
  const isMentor = user?.projectEmployeeIsMentor;

  const {
    currentPage,
    pageSize,
    data: wpsTimeData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();

  const openTimeAllocationModal = params => {
    setActionData(params.row);
    setShowTimeAllocationModal(true);
  };

  const columns = useColumn([
    {
      field: "employeeName",
      headerName: "ApprenticeName",
      renderCell: params => <NameCell params={params} onClick={openTimeAllocationModal} />
    },
    dateColumnDef({
      field: "workDate",
      headerName: "WorkDate"
    }),
    {
      field: "projectName",
      headerName: "ProjectName"
    },
    {
      field: "estimatedHours",
      headerName: "estimatedHours"
    },
    {
      field: "otjHours",
      headerName: "OTJHours"
    },
  
  ]);

  const getWpsTimeAllocationList = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const payload = {
      pageIndex: searchString ? 1 : currentPage + 1,
      pageSize: pageSize,
      searchString: searchString,
      orderByAscending: false,
      orderCol: "workDate",
      ...sortModel
    };
    const { data, totalRecords, error } = await postApi(GET_ALL_EMPLOYEE_TIME_ALLOCATION, payload, {
      params: { employeeid: employeeId, employeeType: isMentor ? "Mentor" : "Apprentice", isEmployee: !isMentor }
    });
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  const handleClose = refresh => {
    setShowTimeAllocationModal(false);
    setActionData(null);
    if (refresh) getWpsTimeAllocationList();
  };

  useEffect(() => {
    getWpsTimeAllocationList();
  }, [getWpsTimeAllocationList]);

  return (
    <>
      <Layout>
        <ProGrid
          title={t("WPSTimeAllocation")}
          columns={columns}
          loading={status === STAUTES.LOADING}
          rows={wpsTimeData}
          searchMode={!!searchString}
          searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
          hideAddButton={true}
          options={{
            getRowId: row => row.projectApprenticeMentorId,
            rowCount: rowCount,
            paginationMode: "server",
            paginationModel: { pageSize: pageSize, page: currentPage },
            onPaginationModelChange: setPagination,
            sortingMode: "server",
            onSortModelChange: setSortModel
          }}
        />
      </Layout>
      <DailyTimeAllocationFormModal
        viewMode={!isMentor}
        show={showTimeAllocationModal}
        handleClose={handleClose}
        actionData={actionData}
        employeeId={employeeId}
      />
    </>
  );
}
