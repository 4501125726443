import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import ProGrid from "../../Components/ProGrid/v2";
import Layout from "../../Components/Layout";
import { STAUTES } from "../../../utils/constant";

import { errorToast } from "../../../utils/toastHelper";
import usePageState from "../../../utils/customHooks/usePageState";
import { postApi, getApi } from "../../../utils/services";
import useColumn from "../../../utils/customHooks/useColumns";
import {
  GET_ALL_DOCUMENTS,
  GET_APPRENTICE_FORM_STATUS,
  GET_DOCUMENT_FROM_S3,
  UPDATE_APPRENTICE_SIGNATURE_STATUS
} from "../../../utils/services/apiPath";
import IconButton from "@mui/material/IconButton";
import GestureIcon from "@mui/icons-material/Gesture";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useSelector } from "react-redux";
import { loginSelector } from "../../store/features/auth/authSlice";
import RegistrationPendingModel from "../../Components/RegistrationPendingModel";
import SignDocumentModel from "../../Components/SignDocumentModel";
import VisibilityIcon from "@mui/icons-material/Visibility";

export default function DocumentList() {
  const {
    currentPage,
    pageSize,
    data: clientsData,
    rowCount,
    status,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();

  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [openRegistrationModal, setOpenRegistrationModal] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const { user } = useSelector(loginSelector);
  const navigate = useNavigate();

  const columns = useColumn(
    [
      {
        field: "formName",
        headerName: "FormName"
      },
      {
        field: "createdDate",
        headerName: "CreatedDate"
      },
      {
        field: "status",
        headerName: "ESignedStatus"
      },
      {
        field: "firstSignerSharedLink",
        headerName: "Actions",
        renderCell: params => (
          <>
            {params.row.status === "Completed" ? (
              <Tooltip title={"Download"} arrow>
                <IconButton onClick={() => handleDownloadDocument(params.row.documentsKey)} color="primary">
                  <FileDownloadIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title={params.row.isApprenticeSigned ? "View Document" : "Sign"} arrow>
                <IconButton onClick={() => handleSignDocument(params.value)} color="primary">
                  {params.row.isApprenticeSigned ? <VisibilityIcon fontSize="small" /> : <GestureIcon fontSize="small" />}
                </IconButton>
              </Tooltip>
            )}
          </>
        )
      }
    ],
    true
  );

  const getAllDocuments = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const payload = {
      pageIndex: currentPage + 1,
      pageSize: pageSize,
      orderByAscending: false,
      searchString: ""
    };
    const { data, totalRecords, error } = await postApi(GET_ALL_DOCUMENTS, payload, {
      params: { employeeId: user.employeeId }
    });
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data, rowCount: totalRecords }));
  }, [currentPage, pageSize, setPageState, setStatus]);

  const handleDownloadDocument = async documentsKey => {
    const response = await postApi(
      GET_DOCUMENT_FROM_S3,
      {},
      {
        params: { documentsKey: documentsKey }
      }
    );
    const pdfUrl = response.data;
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const onClose = () => {
    setOpen(false);
  };

  const handleSignDocument = formLink => {
    setSessionId(formLink);
    setOpen(true);
  };

  const handleProfileUpdate = async () => {
    const { error, data } = await getApi(`${GET_APPRENTICE_FORM_STATUS}/${user.employeeId}`);
    if (error) return errorToast(error);
    if (!data?.isFormCompleted) {
      setOpenRegistrationModal(true);
    }
  };

  useEffect(() => {
    getAllDocuments();
    handleProfileUpdate();
  }, [getAllDocuments]);

  useEffect(() => {
    const allowedOrigins = ['https://trusted-origin.com'];
  
    const eventListener = async event => {
      debugger;
      // if (allowedOrigins.includes(event.origin)) {
        if (isValidJSON(event.data)) {
          const parsedObject = JSON.parse(event.data);
          const signedStatus = parsedObject.data.document.recipients[0].signer_state;
          const docId = parsedObject.data.document.id;
          const obj = {
            documentId: docId,
            SignedStatus: signedStatus,
            Email: parsedObject.data.document.recipients[1].email,
            FirstName: parsedObject.data.document.recipients[1].first_name,
            LastName: parsedObject.data.document.recipients[1].last_name,
            ApprenticeName: parsedObject.data.document.recipients[0].first_name + " " + parsedObject.data.document.recipients[0].last_name,
            FormName: parsedObject.data.document.name
          };
          await postApi(UPDATE_APPRENTICE_SIGNATURE_STATUS, obj);
        } else {
          console.log("Not valid JSON");
        }
      // } else {
      //   console.log("Untrusted origin:", event.origin);
      // }
    };
  
    window.addEventListener("message", eventListener);
  
    return () => {
      window.removeEventListener("message", eventListener);
    };
  }, [getAllDocuments, currentPage, pageSize]);
  
  function isValidJSON(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (error) {
      return false;
    }
  }

  return (
    <>
      <Layout>
        <ProGrid
          title={t("Documents")}
          loading={status === STAUTES.LOADING}
          options={{
            getRowId: row => row.documentId,
            rowCount: rowCount,
            paginationMode: "server",
            paginationModel: { pageSize: pageSize, page: currentPage },
            onPaginationModelChange: setPagination,
            sortingMode: "server",
            onSortModelChange: setSortModel
          }}
          columns={columns}
          hideAddButton={true}
          hideExport={false}
          rows={clientsData || []}
          searchMode={!!searchString}
          searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
        />
      </Layout>

      <RegistrationPendingModel
        open={openRegistrationModal}
        title={t("ApprenticeRegistrationPendingTitle")}
        message={t("ApprenticeRegistrationPendingText")}
        onConfirm={() => navigate("/registration")}
        confirmBtnText={t("Ok")}
      />

      <SignDocumentModel open={open} title={t("SignDocument")} formLink={sessionId} handleClose={onClose} />
    </>
  );
}
