// auth
export const AUTH_LOGIN = "/Authenticate/ClientUserLogin";
export const AUTH_FORGOT_PASS = "/Authenticate/ClientUserForgotPassword";
export const AUTH_VERIFY_LINK = "/Authenticate/ValidateClientUserResetUrl";
export const AUTH_VALIDATE_OTP = "/Authenticate/ValidateOTP";
export const AUTH_RESET_PASSWORD = "/Authenticate/ResetClientUserPassword";
export const GET_BUILD_VERSION = "/Authenticate/GetClientBuildVersion"

export const GET_ALL_VETERAN_STATUS_TYPES = "/DropDown/GetAllVeteranStatusTypes";
export const GET_ALL_ETHNICITY_TYPES = "/DropDown/GetAllEthnicityTypes";
export const GET_ALL_RACE_TYPES = "/DropDown/GetAllRaceTypes";
export const GET_ALL_EDUCATION_LEVEL_TYPES = "/DropDown/GetAllEducationLevelTypes";
export const GET_ALL_DISABILITY_TYPES = "/DropDown/GetAllDisabilityTypes";
export const GET_ALL_RAP_INFO = "/DropDown/GetAllRAPInfo";
export const GET_CAREER_CONNECTION_TYPES = "/DropDown/GetAllCareerConnectionTypes";
export const GET_ALL_EMPLOYEE_STATUS_TYPES = "/EmployeeStatusType/GetAllEmployeeStatusType";
export const GET_EMLOYEE_WEEKLY_TIME_TRACK = "/Rap/GetEmployeeWeeklyTimeTrack";
export const GET_ALL_RAPAPPRENTICE_LIST = "/Rap/GetAllEmployeeApprenticeshipByAssignRapInfoId";
export const GET_ALL_DOCUMENTS = "/ProScoreEmployee/GetAllDocumentByEmployee";
export const GET_DOCUMENT_FROM_S3 = "/ProScoreEmployee/GetDocumentFromS3Bucket";
export const GET_PROSCORE_EMPLOYEE = "ProScoreEmployee/GetProScoreEmployee";
export const UPDATE_EMPLOYEE = "/ProScoreEmployee/UpdateProScoreEmployee";
export const GET_PROJECT_DETAILS = "/ProScoreEmployee/GetProjectDetails";
export const GET_ALL_MENTORS = "/Project/GetAllMentors";
export const REQUEST_MENTORS = "/Project/AddProjectApprenticeMentor";
export const GET_ALL_PROJECT_APPRENTICE = "/ApprenticeMentorMapping/GetMentorApprenticeDropDown";
export const REQUEST_APPRENTICE = "/ApprenticeMentorMapping/AddProjectApprenticeMentor";
export const APPRENTICE_REQUEST_DETAILS = "/ApprenticeMentorMapping/GetApprenticeRequestDetails";
export const ACCEPT_REJECT_MENTOR_REQUEST = "/ApprenticeMentorMapping/AcceptOrRejectMentorRequest";
export const GET_ALL_PROJECT_BY_EMPLOYEE = "/ProScoreEmployee/GetAllProjectByEmployee";
export const GET_APPRENTICE_DASHBOARD = "/Rap/GetApprenticeDashboardDetail";
export const UPLOAD_EMPLOYEE_PROFILE = "/Employee/UploadEmployeeProfile";
export const GET_EMPLOYEE_WPS_ACTIVITY = "/Rap/GetEmployeeWPSActivity";
export const ADD_EMPLOYEE_WEEKLY_TIMETRACK = "/RAP/AddEmployeeWeeklyTimeTrack";
export const GET_ALL_USER_NOTIFICATION = "/ClientUserNotification/GetAllClientUserNotification";
export const UPDATE_USER_NOTIFICATION = "/ClientUserNotification/UpdateClientUserNotification";
export const GET_APPRENTICE_MENTORS = "/ApprenticeMentorMapping/ApprenticeMentorHistory";
export const GET_APPRENTICE_FORM_STATUS = "/Rap/GetApprenticeFormAndDocumentStatus";
export const UPDATE_APPRENTICE_SIGNATURE_STATUS = "/ProScoreEmployee/UpdateApprenticeSignatureStatus";
export const GET_ALL_VIDEOS = "/Video/GetAllVideos";

// mentor Time allocation
export const GET_ALL_EMPLOYEE_TIME_ALLOCATION = "/ProScoreEmployee/GetAllEmployeeWpsTimeAllocation";
export const GET_EMPLOYEE_TIME_ALL_ACTIVITY = "/ProScoreEmployee/GetEmployeeWPSActivity";
export const ADD_EMPLOYEE_WPS_TIME_ALLOCATION = "/ProScoreEmployee/AddEmployeeWpsTimeAllocation";

// courses method

export const GET_EMPLOYEE_COURSES = "/ProScoreEmployee/GetEmployeeCourseDetails";
export const WPS_AUTO_LOGIN = "/wp-json/ts/v1/autologin";