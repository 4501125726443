import React, { useEffect, useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import FormSectionWrapper from "../../Components/ProForms/FormSectionWrapper";
import ProFormsLayout from "../../Components/ProForms/ProFormsLayout";
import { useForm } from "react-hook-form";
import { STAUTES } from "../../../utils/constant";
import ProTextInput from "../../Components/Inputs/TextField";
import ProSelectField from "../../Components/Inputs/SelectField";
import MultiSelectField from "../../Components/Inputs/MultiSelectField";
import Layout from "../../Components/Layout";
import { postApi } from "../../../utils/services";
import {
  changeMode,
  changeModeForField,
  prepareDefaultValues,
  prepareInitialConfig,
  convertResponseDate,
  lessThan16Years,
  formatPayloadDate
} from "../../../utils/formHelper";
import { errorToast, successToast } from "../../../utils/toastHelper";
import MobileField from "../../Components/Inputs/MobileField";
import EmailField from "../../Components/Inputs/EmailField";
import DateField from "../../Components/Inputs/DateField";
import { defaultDropdownConfig, sexOptions, transFormModalData } from "../../../utils/dropdownHelper";
import {
  GET_ALL_ETHNICITY_TYPES,
  GET_ALL_VETERAN_STATUS_TYPES,
  GET_ALL_RACE_TYPES,
  GET_ALL_EDUCATION_LEVEL_TYPES,
  GET_ALL_DISABILITY_TYPES,
  GET_CAREER_CONNECTION_TYPES,
  GET_ALL_RAP_INFO,
  GET_PROSCORE_EMPLOYEE,
  UPDATE_EMPLOYEE
} from "../../../utils/services/apiPath";
import { useTranslation } from "react-i18next";
import ProSsnField from "../../Components/Inputs/SSNField";
import { decryptSSN, encryptSSN } from "../../../utils/crypto";
import { useSelector } from "react-redux";
import { loginSelector } from "../../store/features/auth/authSlice";
import Loading from "../../Components/Loading";

const RegistrationFormConfig = {
  firstName: {
    label: "FirstName",
    perKey: "firstName",
    rules: { required: "First Name Required!" }
  },
  middleName: {
    label: "MiddleName",
    perKey: "middleName"
  },
  lastName: {
    label: "LastName",
    perKey: "lastName",
    rules: { required: "Last Name Required!" }
  },
  cellPhone: {
    label: "CellPhone",
    perKey: "cellPhone"
  },
  email: {
    label: "Email",
    perKey: "email"
  },
  ssn: {
    label: "SSN",
    perKey: "ssn",
    rules: { required: "SSN Required!" }
  },
  dateofBirth: {
    label: "DateofBirth",
    perKey: "dateofBirth",
    defaultValue: null,
    rules: {
      validate: lessThan16Years,
      required: "Date Of Birth Required!"
    }
  },
  sex: {
    label: "Sex",
    perKey: "Sex",
    rules: { required: "Sex Required!" }
  },
  apprenticeshipRegNumber: {
    label: "ApprenticeshipNumber",
    perKey: "apprenticeshipRegNumber"
  },
  ethnicityTypeId: {
    label: "Ethnicity",
    perKey: "ethnicityTypeId",
    rules: { required: "Ethnicity Required!" }
  },
  raceTypeId: {
    label: "Race",
    perKey: "raceTypeId",
    rules: { required: "Race Required!" }
  },
  educationLevelTypeId: {
    label: "EducationalLevel",
    perKey: "educationLevelTypeId",
    rules: { required: "Educational Level Required!" }
  },
  disabilityTypeId: {
    label: "Disability",
    perKey: "disabilityTypeId",
    rules: { required: "Disability Required!" }
  },
  employmentStatus: {
    label: "EmploymentStatus",
    perKey: "employmentStatus",
    rules: { required: "Employment Status Required!" }
  },
  veteranStatusTypeId: {
    label: "VeteranStatus",
    perKey: "veteranStatusTypeId",
    rules: { required: "Veteran Status Required!" }
  },
  rapInfoId: {
    label: "ApprenticeshipsProgram",
    perKey: "rapInfoId"
  },
  careerConnectionTypeId: {
    label: "CareerConnection",
    perKey: "careerConnectionTypeId"
  },
  dateApprenticeshipBegins: {
    label: "DateApprenticeshipBegins",
    defaultValue: null,
    perKey: "dateApprenticeshipBegins"
  },
  anyCredit: {
    label: "LearningExperience",
    perKey: "anyCredit"
  },
  rti: {
    label: "InstructionExperience",
    perKey: "instructionExperience"
  },
  address: {
    label: "Address",
    perKey: "address",
    rules: { required: "Address Required!" }
  },
  address2: {
    label: "Address2",
    perKey: "address2"
  },
  state: {
    label: "State",
    perKey: "state",
    rules: { required: "State Required!" }
  },
  city: {
    label: "City",
    perKey: "city",
    rules: { required: "City Required!" }
  },
  zip: {
    label: "Zip",
    perKey: "zip",
    rules: { required: "Zip Required!" }
  }
};
export const employmentStatusOptions = [
  {
    label: "New Employee",
    value: "New"
  },
  {
    label: "Current Employee",
    value: "Current"
  }
];
export default function RegistrationForm() {
  const { id } = useParams();
  const { t } = useTranslation(); 
  const [ethiniCity, setEthiniCity] = useState([]);
  const [veteranStatus, setVeteranStatus] = useState([]);
  const [race, setRace] = useState([]);
  const [educationLevel, setEducationLevel] = useState([]);
  const [careerConnectionTypeModel, setCareerConnectionTypeModel] = useState([]);
  const [disability, setDisability] = useState([]);
  const [apprenticeShipList, setApprenticeShipList] = useState([]);
  const [status, setStatus] = useState(STAUTES.IDLE);
  const [registrationFormData, setRegistrationFormData] = useState({});
  const { user } = useSelector(loginSelector) || {};
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(RegistrationFormConfig, "edit"));
  const { handleSubmit, control, setValue } = useForm({ defaultValues: prepareDefaultValues(RegistrationFormConfig) });

  const isEditModeOn = useMemo(() => Object.values(formConfig).some(({ mode }) => mode === "edit"), [formConfig]);

  const onEdit = () => setFormConfig(changeMode("edit"));
  const onCancel = () => setFormConfig(changeMode());
  const handleReadValueclick = name => setFormConfig(changeModeForField(name, "edit"));

  const fetchAllEthnicityTypes = async () => {
    const { data } = await postApi(GET_ALL_ETHNICITY_TYPES, defaultDropdownConfig);
    setEthiniCity(data || []);
  };

  const fetchAllVeteranStatusTypes = async () => {
    const { data } = await postApi(GET_ALL_VETERAN_STATUS_TYPES, defaultDropdownConfig);
    setVeteranStatus(data || []);
  };

  const fetchAllRaceTypes = async () => {
    const { data } = await postApi(GET_ALL_RACE_TYPES, defaultDropdownConfig);
    setRace(data || []);
  };

  const fetchAllEducationLevelTypes = async () => {
    const { data } = await postApi(GET_ALL_EDUCATION_LEVEL_TYPES, defaultDropdownConfig);
    setEducationLevel(data || []);
  };

  const fetchAllDisabilityTypes = async () => {
    const { data } = await postApi(GET_ALL_DISABILITY_TYPES, defaultDropdownConfig);
    setDisability(data || []);
  };

  const fetchAllCareerConnectionType = async () => {
    const { data } = await postApi(GET_CAREER_CONNECTION_TYPES, defaultDropdownConfig);
    setCareerConnectionTypeModel(data || []);
  };

  const fetchAllApprenticeshipList = async () => {
    const { data } = await postApi(GET_ALL_RAP_INFO, defaultDropdownConfig);
    setApprenticeShipList(data || []);
  };

  const getProScoreEmployees = useCallback(async () => {
    setIsLoading(true);
    const { error, data: employee } = await postApi(GET_PROSCORE_EMPLOYEE, { employeeId: user.employeeId }, { params: { portalType: "3" } });
    setIsLoading(false);
    if (error) return errorToast(error);
    const {
      employeeStatusTypeIds,
      ethnicityTypeModel,
      veteranStatusTypeModel,
      raceTypeModel,
      educationLevelTypeModel,
      disabilityTypeModel,
      rapInfoModel,
      careerConnectionTypeModel,
      apprenticeStatusTypeModel,
      managerIds,
      languageTypeIds,
      notificationTypeIds,
      employmentTermTypeIds,
      fringePaymentMethodTypeIds,
      birthDate,
      dateApprenticeshipBegins,
      profileMediaModel,
      ssn
    } = employee;

    const data = {
      ...employee,
      ssn: decryptSSN(ssn),
      dateApprenticeshipBegins: convertResponseDate(dateApprenticeshipBegins),
      dateofBirth: convertResponseDate(birthDate),
      veteranStatusTypeId: veteranStatusTypeModel,
      raceTypeId: raceTypeModel,
      profilePicture: profileMediaModel?.path,
      ...transFormModalData({
        employeeStatusTypeId: employeeStatusTypeIds,
        ethnicityTypeId: ethnicityTypeModel,
        educationLevelTypeId: educationLevelTypeModel,
        rapInfoId: rapInfoModel,
        disabilityTypeId: disabilityTypeModel,
        careerConnectionTypeId: careerConnectionTypeModel,
        apprenticeStatusTypeId: apprenticeStatusTypeModel,
        fringePaymentMethodType: fringePaymentMethodTypeIds,
        manager: managerIds,
        languageType: languageTypeIds,
        notificationType: notificationTypeIds,
        employmentTermTypeId: employmentTermTypeIds
      })
    };
    setRegistrationFormData(data);
    Object.keys(formConfig).forEach(field => setValue(field, data[field]));
  });

  const onSubmit = async data => {
    setIsLoading(true);
    if (isSaving) return; 
    setIsSaving(true);
    const payload = {
      ...data,
      employeeId: user.employeeId,
      dateApprenticeshipBegins: formatPayloadDate(data?.dateApprenticeshipBegins),
      birthDate: formatPayloadDate(data?.dateofBirth),
      raceTypeId: data.raceTypeId?.map(({ value }) => value) || null,
      ssn: encryptSSN(data?.ssn),
      veteranStatusTypeId: data.veteranStatusTypeId?.map(({ value }) => value) || null
    };
    const { error } = await postApi(UPDATE_EMPLOYEE, payload, { params: { portalType: 3 } });
    setIsSaving(false); 
    setIsLoading(false);
    setStatus(STAUTES.IDLE);
    setFormConfig(changeMode("edit"));
    if (error) return errorToast(error);
    successToast(`Registration Updated Successfully`);
  };

  useEffect(() => {
    fetchAllEthnicityTypes();
    fetchAllVeteranStatusTypes();
    fetchAllRaceTypes();
    fetchAllEducationLevelTypes();
    fetchAllDisabilityTypes();
    fetchAllCareerConnectionType();
    getProScoreEmployees();
    fetchAllApprenticeshipList();
  }, [id]);

  const defaultFormProps = { control, formValues: registrationFormData, handleReadValueclick };

  return (
    <Layout>
      <ProFormsLayout
        status={status}
        hideBackbutton={true}
        title={t("Registration")}
        beingEdited={isEditModeOn}
        canView={true}
        hideEdit={false}
        onEdit={onEdit}
        onCancel={onCancel}
        onSave={handleSubmit(onSubmit)}
        buttonLabel={"Save"}
        isSaving={isSaving}
      >
        <FormSectionWrapper title={t("BasicInformation")}>
          <ProTextInput {...defaultFormProps} {...formConfig.firstName} />
          <ProTextInput {...defaultFormProps} {...formConfig.middleName} />
          <ProTextInput {...defaultFormProps} {...formConfig.lastName} />
          <MobileField {...defaultFormProps} {...formConfig.cellPhone} />
          <EmailField {...defaultFormProps} {...formConfig.email} />
          <ProSsnField {...defaultFormProps} {...formConfig.ssn} />
          <DateField {...defaultFormProps} {...formConfig.dateofBirth} />
          <ProSelectField {...defaultFormProps} {...formConfig.sex} options={sexOptions} />
          <ProTextInput {...defaultFormProps} {...formConfig.apprenticeshipRegNumber} fieldProps={{ disabled: true }} mode="read" />
        </FormSectionWrapper>
        <FormSectionWrapper title={t("ApprenticeInformation")}>
          <ProSelectField {...defaultFormProps} {...formConfig.ethnicityTypeId} options={ethiniCity} />
          <MultiSelectField {...defaultFormProps} {...formConfig.veteranStatusTypeId} options={veteranStatus} />
          <MultiSelectField {...defaultFormProps} {...formConfig.raceTypeId} options={race} />
          <ProSelectField {...defaultFormProps} {...formConfig.educationLevelTypeId} options={educationLevel} />
          <ProSelectField {...defaultFormProps} {...formConfig.disabilityTypeId} options={disability} />
          <ProSelectField
            {...defaultFormProps}
            {...formConfig.employmentStatus}
            options={employmentStatusOptions}
            fieldProps={{ disabled: true }}
            mode="read"
          />
          <ProSelectField {...defaultFormProps} {...formConfig.rapInfoId} options={apprenticeShipList} fieldProps={{ disabled: true }} mode="read" />
          <ProSelectField {...defaultFormProps} {...formConfig.careerConnectionTypeId} options={careerConnectionTypeModel} mode="read" />
          <DateField {...defaultFormProps} {...formConfig.dateApprenticeshipBegins} fieldProps={{ disabled: true }} mode="read" />
          <ProTextInput {...defaultFormProps} {...formConfig.anyCredit} fieldProps={{ disabled: true }} mode="read" />
          <ProTextInput {...defaultFormProps} {...formConfig.rti} fieldProps={{ disabled: true }} mode="read" />
        </FormSectionWrapper>
        <FormSectionWrapper title={t("AddressInformation")}>
          <ProTextInput {...defaultFormProps} {...formConfig.address} />
          <ProTextInput {...defaultFormProps} {...formConfig.address2} />
          <ProTextInput {...defaultFormProps} {...formConfig.state} />
          <ProTextInput {...defaultFormProps} {...formConfig.city} />
          <ProTextInput {...defaultFormProps} {...formConfig.zip} />
        </FormSectionWrapper>
        {isLoading && <Loading />}
      </ProFormsLayout>
    </Layout>
  );
}
