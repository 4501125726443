import { useRef, useState } from "react";
import { IconButton, Box, List, ListItem, Typography, ListItemText, Popover, Tooltip, styled } from "@mui/material";
import internationalization from "../../../i18n/i18n";
import { useTranslation } from "react-i18next";
import { US as USFlag, ES as ESFlag, HT as HTFlag } from "country-flag-icons/react/3x2";

const SectionHeading = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
        padding: ${theme.spacing(2, 2, 0)};
`
);

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
        width: ${theme.spacing(7)};
        height: ${theme.spacing(7)};

        svg {
          width: 30px;
        }
`
);

function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const getLanguage = i18n.language;

  const switchLanguage = ({ lng }) => {
    internationalization.changeLanguage(lng);
  };
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Tooltip arrow title={t("LanguageSwitcher")}>
        <IconButtonWrapper color="primary" ref={ref} onClick={handleOpen}>
          {getLanguage === "en" && <USFlag title="English" />}
          {getLanguage === "en-US" && <USFlag title="English" />}
          {getLanguage === "en-GB" && <USFlag title="English" />}
          {getLanguage === "es" && <ESFlag title="Spanish" />}
          {getLanguage === "ht" && <HTFlag title="Haitian Creole" />}
        </IconButtonWrapper>
      </Tooltip>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <Box
          sx={{
            maxWidth: 240
          }}
        >
          <SectionHeading variant="body2">
            {t("LanguageSwitcher")}
          </SectionHeading>
          <List
            sx={{
              p: 2,
              svg: {
                width: 26,
                mr: 1
              }
            }}
            component="nav"
          >
            <ListItem
              className={getLanguage === "en" || getLanguage === "en-US" ? "active" : ""}
              component="button"
              onClick={() => {
                switchLanguage({ lng: "en" });
                handleClose();
              }}
              sx={{ background: "none", border: "none", cursor: "pointer" }}
            >
              <USFlag title="English" />
              <ListItemText
                sx={{
                  pl: 1
                }}
                primary="English"
              />
            </ListItem>
            <ListItem
              className={getLanguage === "es" ? "active" : ""}
              component="button"
              onClick={() => {
                switchLanguage({ lng: "es" });
                handleClose();
              }}
              sx={{ background: "none", border: "none", cursor: "pointer" }}
            >
              <ESFlag title="Spanish" />
              <ListItemText
                sx={{
                  pl: 1
                }}
                primary="Spanish"
              />
            </ListItem>
            <ListItem
              className={getLanguage === "ht" ? "active" : ""}
              component="button"
              onClick={() => {
                switchLanguage({ lng: "ht" });
                handleClose();
              }}
              sx={{ background: "none", border: "none", cursor: "pointer" }}
            >
              <HTFlag title="Haitian Creole" />
              <ListItemText
                sx={{
                  pl: 1
                }}
                primary="Haitian Creole"
              />
            </ListItem>
          </List>
        </Box>
      </Popover>
    </>
  );
}

export default LanguageSwitcher;
