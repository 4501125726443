import { Dialog, Paper, Box, Typography, Grid } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

export default function SignDocumentModel({ open, title, formLink, handleClose }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      keepMounted
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box sx={{ width: "100%", height: "100%" }} style={{ "overflow-x": "hidden" }}>
        <Paper sx={{ py: 3, px: 2 }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h6" component="h6" gutterBottom textTransform="uppercase">
                {title}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        <div style={{ width: "100%", height: window.innerHeight * 0.7, overflowY: "auto" }}>
          <iframe title="PDF Viewer" width="100%" height="100%" src={formLink} />
        </div>
      </Box>
    </Dialog>
  );
}

SignDocumentModel.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  formLink: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};