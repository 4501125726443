import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ProPreLoginLayout from "../../Components/ProPreLoginLayout";
import ProTextInput from "../../Components/Inputs/TextField";
import ProButton from "../../Components/ProButton";
import ProSelectField from "../../Components/Inputs/SelectField";
import { useForm } from "react-hook-form";
import { postApi, getApi } from "../../../utils/services";
import { GET_ALL_PROJECT_APPRENTICE, REQUEST_APPRENTICE } from "../../../utils/services/apiPath";
import { prepareDefaultValues, prepareInitialConfig, fieldTruePermission, formatPayloadDate, renderFormattedDate } from "../../../utils/formHelper";
import { errorToast } from "../../../utils/toastHelper";
import ProRadioGroup from "../../Components/Inputs/RadioGroup";
import dayjs from "dayjs";

export const yesNoOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false }
];

const MentorFormConfig = {
  apprentice: {
    name: "Apprentice",
    label: "Apprentice",
    rules: { required: "Apprentice is required!" }
  },
  isMentorWorking: {
    label: "Are you Working Today?",
    defaultValue: null,
    rules: { required: "This field is required!" }
  },
  notWorkingReason: {
    name: "notWorkingReason",
    label: "Reason",
    fieldProps: { multiline: true, rows: 4 }
  }
};

const todayDate = renderFormattedDate(dayjs());

const RequestApprentice = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const projectGuid = queryParams.get("projectGuid");
  const [projectDetail, setProjectDetail] = useState();
  const [apprentice, setApprentice] = useState([]);
  const [isRequested, setIsRequested] = useState(false);
  const [formConfig] = useState(prepareInitialConfig(MentorFormConfig, "edit"));
  const { handleSubmit, control, watch } = useForm({ defaultValues: prepareDefaultValues(MentorFormConfig) });
  const isMentorWorking = watch("isMentorWorking");
  const [messages, setMessages] = useState("");

  const onSubmit = async data => {
    const payload = {
      apprenticeGuid: data.Apprentice,
      date: formatPayloadDate(dayjs()),
      isMentorWorking: data.isMentorWorking == "true",
      notWorkingReason: data.notWorkingReason
    };
    const { error } = await postApi(REQUEST_APPRENTICE, payload);
    if (error) return errorToast(error);
    fetchApprenticeDetails();
  };

  const fetchApprenticeDetails = async () => {
    const { data, error } = await getApi(`${GET_ALL_PROJECT_APPRENTICE}?projectGuid=${projectGuid}`);
    if (error) {
      setIsRequested(true);
      setMessages(error);
    } else {
      setIsRequested(false);
      setProjectDetail(data);
      setApprentice(Array.isArray(data.apprenticeEmployees) ? data.apprenticeEmployees : []);
    }
  };

  useEffect(() => {
    fetchApprenticeDetails();
  }, []);

  const defaultFormProps = { control, formValues: MentorFormConfig, isAddMode: false };

  return (
    <ProPreLoginLayout heading="Apprentice Request">
      <div className="proLogin">
        {!isRequested ? (
          <div className="proLogin_form">
            <div className="proLogin_form_input">
              <ProTextInput label={"Today's Date"} control={control} mode={"read"} name={"todayDate"} formValues={{ todayDate }} />
            </div>
            <div className="proLogin_form_input">
              <ProTextInput label={"Project Name"} control={control} mode={"read"} name={"projectName"} formValues={projectDetail} />
            </div>
            <div className="proLogin_form_input">
              <ProRadioGroup {...defaultFormProps} {...formConfig.isMentorWorking} options={yesNoOptions} permission={fieldTruePermission} />
            </div>
            {isMentorWorking == "true" && (
              <div className="proLogin_form_input">
                <ProSelectField {...defaultFormProps} {...formConfig.apprentice} options={apprentice} permission={fieldTruePermission} />
              </div>
            )}
            {isMentorWorking == "false" && (
              <div className="proLogin_form_input">
                <ProTextInput {...defaultFormProps} {...formConfig.notWorkingReason} permission={fieldTruePermission} />
              </div>
            )}
            <div className="proLogin_form_button" style={{ marginBottom: "10px" }}>
              <ProButton style={{ width: "100%" }} onClick={handleSubmit(onSubmit)}>
                Send Request
              </ProButton>
            </div>
          </div>
        ) : (
          <p style={{ color: "green", textAlign: "center", marginTop: "5px" }}>
            {messages}
          </p>
        )}
      </div>
    </ProPreLoginLayout>
  );
};

export default RequestApprentice;
