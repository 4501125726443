import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import ProGrid from "../../Components/ProGrid/v2";
import usePageState from "../../../utils/customHooks/usePageState";
import useColumn from "../../../utils/customHooks/useColumns";
import { STAUTES } from "../../../utils/constant";
import { useTranslation } from "react-i18next";
import { postApi, getApi } from "../../../utils/services";
import { GET_APPRENTICE_MENTORS, GET_APPRENTICE_FORM_STATUS } from "../../../utils/services/apiPath";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { loginSelector } from "../../store/features/auth/authSlice";
import { dateColumnDef } from "../../Components/ProGrid/components/FormatCell";
import RegistrationPendingModel from "../../Components/RegistrationPendingModel";
import { v4 as uuidV4 } from "uuid";

export default function MyMentors() {
  const { t } = useTranslation();
  const { user } = useSelector(loginSelector);
  const [open, setOpen] = useState(false);

  const {
    currentPage,
    pageSize,
    data: projectsData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();
  const navigate = useNavigate();

  const handleProfileUpdate = async () => {
    const { data } = await getApi(`${GET_APPRENTICE_FORM_STATUS}/${user.employeeId}`);
    if (!data?.isFormCompleted) {
      setOpen(true);
    }
  };

  const columns = useColumn(
    [
      {
        field: "mentorFirstName",
        headerName: "MentorName",
        valueGetter: ({ row }) => `${row.mentorFirstName || ""} ${row.mentorLastName || ""}`
      },
      {
        field: "apprenticeFirstName",
        headerName: "ApprenticeName",
        valueGetter: ({ row }) => `${row.apprenticeFirstName || ""} ${row.apprenticeLastName || ""}`
      },
      {
        field: "projectName",
        headerName: "ProjectName"
      },
      dateColumnDef({
        field: "workDate",
        headerName: "WorkDate"
      })
    ],
    true
  );

  const getMentors = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const payload = {
      pageIndex: searchString ? 1 : currentPage + 1,
      pageSize: pageSize,
      searchString: searchString,
      orderByAscending: true,
      ...sortModel
    };
    const { data, totalRecords} = await postApi(GET_APPRENTICE_MENTORS, payload);

    setStatus(STAUTES.IDLE);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: (data || []).map(rec => ({ ...rec, id: uuidV4() })), rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  useEffect(() => {
    handleProfileUpdate();
    getMentors();
  }, []);

  return (
    <>
      <Layout>
        <ProGrid
          title={t("MyMentors")}
          columns={columns}
          loading={status === STAUTES.LOADING}
          rows={projectsData}
          searchMode={!!searchString}
          searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
          hideAddButton={true}
          hideExport={false}
          options={{
            getRowId: row => row.id,
            rowCount: rowCount,
            paginationMode: "server",
            paginationModel: { pageSize: pageSize, page: currentPage },
            onPaginationModelChange: setPagination,
            sortingMode: "server",
            onSortModelChange: setSortModel
          }}
        />
      </Layout>

      <RegistrationPendingModel
        open={open}
        title={t("ApprenticeRegistrationPendingTitle")}
        message={t("ApprenticeRegistrationPendingText")}
        onConfirm={() => navigate("/registration")}
        confirmBtnText={t("Ok")}
      />
    </>
  );
}
