import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { loginSelector } from "../store/features/auth/authSlice";
import PropTypes from "prop-types";

const ProtectedRoute = ({ Component }) => {
  const location = useLocation();
  const { isAuthenticated, isMounted } = useSelector(loginSelector);

  if (isMounted && !isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  if(!isAuthenticated) return null;
  return <Component />;
};

ProtectedRoute.propTypes = {
  Component: PropTypes.elementType.isRequired,
};

export default ProtectedRoute;
