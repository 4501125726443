import React, { useEffect, useMemo, useState } from "react";
import "./SubHeader.scss";
import DownArrowIcon from "../../Assets/IconComponents/DownArrow";
import { NavLink, useLocation } from "react-router-dom";
import { useTheme } from "../../ContextStore/ThemeContext";
import PropTypes from "prop-types";
import { WPS_AUTO_LOGIN } from "../../../utils/services/apiPath";
import { useSelector } from "react-redux";
import { loginSelector } from "../../store/features/auth/authSlice";
import { LMS_AUTO_LOGIN_URL } from "../../../utils/constant";
import axios from "axios";

const Tab = ({ name, tab, Icon, active, arrow, subMenus, onTabClick }) => {
  const { theme } = useTheme();
  const [showDropdown, setShowDropdown] = useState(false);
  const { user } = useSelector(loginSelector);

  const handleTabClick = e => {
    if (arrow) {
      e.preventDefault();
      setShowDropdown(!showDropdown);
    } else onTabClick(tab);
  };

  useEffect(() => {
    const outsideClick = e => {
      if (!e.target.closest(".drop")) setShowDropdown(false);
    };

    if (showDropdown) document.addEventListener("click", outsideClick);
    return () => {
      document.removeEventListener("click", outsideClick);
    };
  }, [showDropdown]);

  const getIconColor = (active, showDropdown, theme) => {
    if (active || showDropdown) {
      return "#00B0EE";
    } else if (theme === "dark") {
      return "#ffffff";
    } else {
      return "#00050B";
    }
  };

  const getArrowColor = (active, theme) => {
    if (active) {
      return "#00B0EE";
    } else if (theme === "dark") {
      return "#6D7080";
    } else {
      return "#00050B";
    }
  };

  const onNavClick = async (e, menu) => {
    if (menu.shouldFireClick) {
      e.preventDefault();
      setShowDropdown(false);
      if (menu.title.includes("OSHA")) {
        window.open(menu.link, "_blank");
      } else {
        const { data } = await axios.create().post(
          `${LMS_AUTO_LOGIN_URL}${WPS_AUTO_LOGIN}`,
          {
            username: user?.userName,
            email: user?.email
          },
          {
            headers: { Authorization: `Bearer wHgY4Gx5zbfyca7uKZJk8WE26TQPpSqC3rL` }
          }
        );

        if (data.user_exist) {
          window.open(`${menu.link.replace(/\/+$/, "")}?token=${data.token}`, "_blank");
        }
      }
    }
  };

  const iconColor = getIconColor(active, showDropdown, theme);
  const arrowColor = getArrowColor(active, theme);

  return (
    <button className={`tab ${active ? "active" : ""} ${showDropdown ? "drop" : ""}`} onClick={handleTabClick}>
      <div className="tab_main">
        <Icon color={iconColor} />
        <span>{name}</span>
        {arrow && <DownArrowIcon color={arrowColor} />}
      </div>
      {showDropdown && arrow && (
        <div className="tab_dropdown">
          {subMenus?.length !== 0 &&
            subMenus?.map((menu, index) => {
              return (
                <NavLink key={Number(index)} onClick={e => onNavClick(e, menu)} to={menu.link} className="tab_dropdown_item">
                  <span>{menu?.title}</span>
                </NavLink>
              );
            })}
        </div>
      )}
    </button>
  );
};

Tab.propTypes = {
  name: PropTypes.string.isRequired,
  tab: PropTypes.object.isRequired,
  Icon: PropTypes.elementType.isRequired,
  active: PropTypes.bool.isRequired,
  arrow: PropTypes.bool.isRequired,
  subMenus: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired
    })
  ),
  onTabClick: PropTypes.func.isRequired
};

export const MenuItems = ({ tabs, activeTab, onTabClick }) => {
  return (
    <div className="subheader">
      {tabs.map((tab, index) => (
        <Tab
          key={Number(index)}
          tab={tab}
          name={tab.title}
          Icon={tab.icon}
          arrow={tab.hasArrow}
          subMenus={tab.subMenus}
          active={activeTab === tab.title}
          onTabClick={onTabClick}
        />
      ))}
    </div>
  );
};

MenuItems.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.elementType.isRequired,
      hasArrow: PropTypes.bool,
      subMenus: PropTypes.arrayOf(PropTypes.string)
    })
  ).isRequired,
  activeTab: PropTypes.string.isRequired,
  onTabClick: PropTypes.func.isRequired
};

const SubHeader = ({ tabs, onTabClick }) => {
  const location = useLocation();

  const activeTabData = useMemo(() => {
    return tabs.find(tab => location.pathname.startsWith(tab.to));
  }, [location.pathname, tabs]);

  return (
    <div className="subheader">
      {tabs.map((tab, index) => (
        <Tab
          key={Number(index)}
          tab={tab}
          name={tab.title}
          Icon={tab.icon}
          arrow={tab.hasArrow}
          subMenus={tab.subMenus}
          active={activeTabData?.title === tab.title}
          onTabClick={onTabClick}
        />
      ))}
    </div>
  );
};

SubHeader.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      icon: PropTypes.elementType,
      hasArrow: PropTypes.bool,
      subMenus: PropTypes.array
    })
  ).isRequired,
  onTabClick: PropTypes.func.isRequired
};

export default SubHeader;
